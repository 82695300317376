/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Swingsy = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        lightbox.option({
          'resizeDuration': 400,
          'wrapAround': true,
          'showImageNumberLabel': false
        });

        $.fn.select2.defaults.set("theme", "bootstrap");

        /* dropdown and filter select */
        $(".js-basic-single").select2({
          minimumResultsForSearch: Infinity
        });

        /* Select2 plugin as tagpicker */
        $(".js-basic-multiple").select2({
          closeOnSelect: false
        });

        $('body').tooltip({
          // Handle tooltips dinamically
          selector: '[data-toggle="tooltip"]',
          // Store tooltips in body to be displayed even with overflow:hidden
          container: 'body'
        });

        $('body').popover({
          selector: '[data-toggle="popover"]',
          trigger: "hover"
        });

        /* prevent bootstrap dropdown to close on clik for the search dropdown */
        // https://stackoverflow.com/questions/25089297/twitter-bootstrap-avoid-dropdown-menu-close-on-click-inside#comment60177100_25196101
        $(document).on('click.bs.dropdown', '.dropdown-menu.keep-open', function (e) {
          e.stopPropagation();
        });

        // add "reset" btn behaviour for multiple select2
        $(".btn-reset").on("click", function () { $(this).parents("form").find(".js-basic-multiple").val(null).trigger("change"); });
        // add "close" btn behaviour
        $(".btn-dropdown-close").on("click", function () { $(this).parents(".dropdown").find(".dropdown-toggle").dropdown('toggle'); });

        //toggle search in messages for mobiles
        $(document).on("click", ".toogle-search-contact", function () {
          $(this).parents(".navbar").toggleClass("active");
        });
        //copy invite code in the "invite" modal by pressing "copy code" btn
        $(document).on("click", ".copy-code-btn", function () {
          console.log("click");
          var $this = $(this),
            msg = $this.data("code");

          // create hidden text element, if it doesn't already exist
          var targetId = "_hiddenCopyText_";
          var origSelectionStart, origSelectionEnd;

          // must use a temporary form element for the selection and copy
          var $target = $("<input id='_hiddenCopyText_' type='text'>");
          $target.val("some text");
          var currentFocus = document.activeElement;
          $("body").append($target);
          console.log('IS COPY SUPPORTED?:', document.queryCommandSupported('copy'));
          console.log($target.val());
          $("#_hiddenCopyText_").setSelectionRange(0, $target.value.length);
          console.log(window.getSelection().toString());
          document.execCommand("copy", false, null);

          $target.remove();

          // select the content -- restore focus after if needed
          // var currentFocus = document.activeElement;
          // target.focus();
          // target.setSelectionRange(0, target.value.length);

          // copy the selection
          // var succeed;
          // try {
          //     succeed = true;
          // 	  document.execCommand("copy");
          //     $this.addClass("btn-outline-success").text("Code copié!");
          // } catch(e) {
          //     succeed = false;
          //     $this.addClass("btn-outline-danger");
          // }
          // restore original focus
          // if (currentFocus && typeof currentFocus.focus === "function") {
          //     currentFocus.focus();
          // }

          // clear temporary content
          // target.textContent = "";
        });

        new Clipboard('.btn');

        // Analytics
        $(document).on('shown.bs.modal', '#modalSignin', function () {
          if (window.ga) window.ga('send', 'event', 'Inscription', 'open');
        });
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Swingsy;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
